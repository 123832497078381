import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { getTPASettignsLiveSiteEditingDeprecated } from '@wix/communities-blog-client-common';
import { importPrivateProfileActions } from '../../containers/private-profile-actions';
import ProtectedButton from '../../containers/protected-button';
import withAuth from '../../hoc/with-auth';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import withTranslate from '../../hoc/with-translate';
import DropdownButton from '../dropdown-button';
import HeaderAvatarImage from '../header-avatar-image';
import NotificationsLink from '../link/notifications-link';
import NotificationButton from '../notification-button';
import { connect } from '../runtime-context';
import SearchButton from '../search-button';
import styles from './header-buttons.scss';

export const HeaderButtons = ({
  currentUser,
  showLoginButton,
  showSearch,
  isAuthenticated,
  t,
  isLiveSiteEditingDeprecated,
}) => (
  <div className={classNames(styles.container, 'header-buttons')}>
    {showSearch ? renderSearchButton() : null}
    {showLoginButton
      ? renderNotificationButton(isAuthenticated, NotificationsLink)
      : null}
    {showLoginButton && !isLiveSiteEditingDeprecated
      ? renderAvatar(isAuthenticated, currentUser, t)
      : null}
  </div>
);

function renderSearchButton() {
  return (
    <div className={styles.searchButton}>
      <SearchButton />
    </div>
  );
}

function renderNotificationButton(isAuthenticated, NotificationsLink) {
  const iconClassName = 'blog-button-primary-icon-fill';
  const bubbleClassName = 'blog-icon-secondary-background-color';

  if (isAuthenticated) {
    return (
      <div className={styles.notificationButtonSpacing}>
        <NotificationButton
          component={NotificationsLink}
          iconClassName={iconClassName}
          bubbleClassName={bubbleClassName}
        />
      </div>
    );
  }
}

function renderAvatar(isAuthenticated, currentUser, t) {
  if (isAuthenticated) {
    return (
      <DropdownButton
        actionsContainerClassName={styles.profileActions}
        position={DropdownButton.POSITION_RIGHT}
        icon={<HeaderAvatarImage user={currentUser} />}
      >
        {async () => {
          const PrivateProfileActions = await importPrivateProfileActions();
          return <PrivateProfileActions user={currentUser} />;
        }}
      </DropdownButton>
    );
  }

  const signUpClassName = classNames(
    styles.signUp,
    'blog-button-primary-text-color',
    'header-buttons__sign-up',
  );

  return (
    <ProtectedButton tagName="span" className={signUpClassName}>
      {t('header-buttons.sign-up')}
    </ProtectedButton>
  );
}

const mapRuntimeToProps = (state) => ({
  isLiveSiteEditingDeprecated: getTPASettignsLiveSiteEditingDeprecated(state),
});

HeaderButtons.propTypes = {
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLoginButton: PropTypes.bool,
  t: PropTypes.func,
  isLiveSiteEditingDeprecated: PropTypes.bool,
};

export default flowRight(
  withAuth,
  withBlogMenuSettings,
  withTranslate,
  connect(mapRuntimeToProps),
)(HeaderButtons);
