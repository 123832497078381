import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger = ({
  children,
  title,
  containerBackgroundClass,
  containerFontClass,
  arrowDownIconClass,
  ...props
}) => {
  containerBackgroundClass = 'blog-card-background-color';
  containerFontClass = 'blog-text-color';

  const className = classNames(
    styles.container,
    'blog-card-background-color',
    'blog-text-color',
    'categories-button',
    styles.boxShadow,
  );

  const iconFillClass = 'blog-icon-fill';

  return (
    <div className={className} {...props}>
      <div className={styles.wrapper}>
        <span data-hook="category-title" className={styles.title}>
          {title}
        </span>
        <ArrowDownIcon className={classNames(iconFillClass, styles.icon)} />
        {children}
      </div>
    </div>
  );
};

ChangeCategoryTrigger.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  containerBackgroundClass: PropTypes.string,
  containerFontClass: PropTypes.string,
  arrowDownIconClass: PropTypes.string,
};

export default ChangeCategoryTrigger;
