import React from 'react';
import classNames from 'classnames';
import Link from '../../link/internal-link';
import { Props } from '.';
import styles from '../header-navigation.scss';

export const HeaderNavigationLink = ({
  link: { path, onNavigation, text },
  currentPathDecoded,
  linkArray,
  index,
}: Props) => {
  const isActive = currentPathDecoded === path;

  const linkClassNames = classNames(
    styles.link,
    'blog-navigation-container-color blog-navigation-container-font ',
    'blog-navigation-link-hover-color',
    isActive && 'blog-navigation-link-active-color',
    ...(index === 0 || index === linkArray.length - 1
      ? ['has-custom-focus', styles.linkAccessibility]
      : []),
  );

  return (
    <li data-hook={`header-navigation__${path}`}>
      <Link
        className={linkClassNames}
        to={path}
        addHoverClasses={false}
        isActive={isActive}
        onNavigation={onNavigation}
      >
        {text}
      </Link>
    </li>
  );
};
