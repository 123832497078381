import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { NormalizedCategory } from '@wix/communities-blog-client-common';
import MoreCategoriesDropdown from '../../more-categories-dropdown';

type Props = {
  categories: NormalizedCategory[];
  width: number | undefined;
  currentPath: string;
  addNavigationToHeaderLinks: (categories: NormalizedCategory[]) => any;
  showAccessibilityAlert: (message: string) => void;
};

export const HeaderNavigationMoreButton = ({
  categories,
  width,
  currentPath,
  addNavigationToHeaderLinks,
  showAccessibilityAlert,
}: Props) => {
  const { t } = useTranslation();
  const links = addNavigationToHeaderLinks(categories);

  links.shift();

  return (
    categories && (
      <li id="categories-more-button">
        <MoreCategoriesDropdown
          currentPath={currentPath}
          items={links}
          width={width}
          moreText={t('header.more-button')}
          className="blog-navigation-container-font blog-navigation-link-hover-fill blog-navigation-link-hover-color blog-navigation-container-color blog-navigation-container-fill"
          hoverClassName="blog-navigation-container-hover-color blog-navigation-container-hover-fill"
          activeClassName="blog-navigation-link-active-color blog-navigation-container-active-color blog-navigation-container-active-fill"
          onNavigation={showAccessibilityAlert}
        />
      </li>
    )
  );
};
