import { find } from 'lodash';
import { createSelector } from 'reselect';
import {
  getCategoryPath,
  getFeedTitle,
} from '@wix/communities-blog-client-common';
import { getCurrentMatchPathname } from '../router/router-selectors';
import * as getHeaderLinksService from '../services/get-header-links';
import { getCategories } from '../store/categories/categories-selectors';
import { getViewerAllPostsLabelTranslation } from './viewer-all-post-label-translation-selectors';

export const getFeedAndCategoryLinks = createSelector(
  [
    getCategories,
    getFeedTitle,
    getViewerAllPostsLabelTranslation,
    getCategoryPath,
    (state) => state?.tpaSettings?.settings?.allPostsFeedLabel,
  ],
  (
    categories,
    feedTitle,
    translatedFeedTitle,
    categoryPath,
    allPostsFeedLabelFromSettings,
  ) =>
    getHeaderLinksService.getHeaderLinks({
      categories,
      feedTitle:
        translatedFeedTitle || feedTitle || allPostsFeedLabelFromSettings,
      categoryPath,
      withFeedLink: true,
    }),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) =>
    find(links, (link) => link.path === currentPath) || links[0],
);
