import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import withTranslate from '../../hoc/with-translate';
import { SearchIcon } from '../icons/search-icon';
import Link from '../link/internal-link';
import styles from './search-button.scss';

class SearchButton extends Component {
  render() {
    const { t } = this.props;

    const iconClassName = 'blog-button-primary-icon-fill';

    return (
      <Link to="/search" className={classNames(styles.button, 'search-button')}>
        <SearchIcon className={iconClassName} />
        <span className={styles.hiddenLabel}>{t('search-button.search')}</span>
      </Link>
    );
  }
}

SearchButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default flowRight(withTranslate)(SearchButton);
