import React from 'react';
import { AccessibilityAlert } from '../../accessibility-alert';

type Props = {
  accessibilityAlert: string;
};

export const HeaderNavigationAccessibilityAlert = ({
  accessibilityAlert,
}: Props) => <AccessibilityAlert message={accessibilityAlert} />;
