import { useSelector } from '../../../common/components/runtime-context';
import { getCurrentMatch } from '../../../common/router/router-selectors';

export type MatchedRoute = ReturnType<typeof getCurrentMatch>;

export const useMatchedRoute = () =>
  useSelector(
    (state): MatchedRoute => ({
      ...getCurrentMatch(state),
    }),
  );
