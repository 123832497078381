import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import HeaderButtons from '../header-buttons';
import HeaderTitle from '../header-title';
import styles from './header.scss';

export class Header extends Component {
  render() {
    const {
      left,
      right,
      menu,
      contentFontClassName,
      hideNavBar,
      showBlogMenu,
      showCategoryLabels,
    } = this.props;

    const containerClassName = classNames(
      styles.headerContainer,
      'blog-button-primary-text-color',
      contentFontClassName,
    );

    const showMenu = menu && showBlogMenu && showCategoryLabels;
    const wrapperClassName = classNames(
      styles.header,
      'blog-button-background-color',
    );

    const menuClassName = 'blog-button-background-color';

    return (
      <div className={containerClassName}>
        {!hideNavBar && showBlogMenu && (
          <div className={wrapperClassName}>
            {left}
            {right}
          </div>
        )}
        {showMenu && <div className={menuClassName}>{menu}</div>}
      </div>
    );
  }
}

Header.propTypes = {
  contentFontClassName: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  hideNavBar: PropTypes.bool,
  showBlogMenu: PropTypes.bool,
  showCategoryLabels: PropTypes.bool,
};

Header.defaultProps = {
  right: <HeaderButtons />,
  left: (
    <div className={styles.left}>
      <span className={styles.title}>
        <HeaderTitle />
      </span>
    </div>
  ),
};

export default flowRight(withFontClassName, withBlogMenuSettings)(Header);
