import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { isTextDirectionRTL } from '../../../rich-content/services/text-direction';
import withDeviceType from '../../hoc/with-device-type';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import { getPostTitleFontSizeMobile } from '../../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import DotDotDot from '../dotdotdot';
import Link from '../link/internal-link';
import { connect } from '../runtime-context';
import styles from './post-title.scss';

const renderDottedTitle = (title, lineCount, className) =>
  lineCount ? (
    <DotDotDot
      useExactLineHeight={true}
      clamp={lineCount}
      className={className}
    >
      {title}
    </DotDotDot>
  ) : (
    <span className={className}>{title}</span>
  );

// eslint-disable-next-line react/prop-types
const renderTitle = ({
  title,
  to,
  lineCount,
  linkClassName,
  fontClassName,
  fullRoute,
}) => {
  const className = 'post-title__text';
  const dottedTitle = renderDottedTitle(title, lineCount, fontClassName);

  return to ? (
    <Link
      fullRoute={fullRoute}
      className={classNames(className, styles.link, linkClassName)}
      to={to}
    >
      {dottedTitle}
    </Link>
  ) : (
    <span className={classNames(className, fontClassName)}>{dottedTitle}</span>
  );
};

export const PostTitle = ({
  applyFeedDesign,
  style,
  className,
  getPostClassName,
  isInPostPage,
  layoutName,
  lineCount,
  linkClassName,
  navigation,
  textColorClassName,
  title,
  to,
  type,
  isDesktop,
  isMobile,
  postTitleFontSizeMobile,
  showCategoryLabel,
  fullRoute,
}) => {
  const Component = isInPostPage ? 'h1' : 'h2';
  const titleDirection =
    typeof title === 'string' && isInPostPage && isTextDirectionRTL(title)
      ? 'rtl'
      : undefined;
  const titleClassName = classNames(
    styles.title,
    styles[type],
    className,
    !applyFeedDesign && textColorClassName,
    'post-title',
    'blog-hover-container-element-color',
    type === PostTitle.SINGLE && styles.singleNew,
    getPostClassName(
      getFeedColorClassName(layoutName, 'title-color'),
      'title-font',
    ),
    isInPostPage && 'blog-post-page-title-font',
    showCategoryLabel && styles.withCategoryLabel,
  );
  const isInMobilePostPage = isInPostPage && isMobile;
  const titleStyle = isInMobilePostPage
    ? { ...style, fontSize: postTitleFontSizeMobile }
    : style;

  return (
    <Component
      className={titleClassName}
      style={titleStyle}
      data-hook="post-title"
      dir={titleDirection}
    >
      {renderTitle({
        title,
        to,
        lineCount,
        navigation,
        linkClassName,
        fontClassName: isDesktop ? className : undefined,
        fullRoute,
      })}
    </Component>
  );
};
PostTitle.SMALL = 'small';
PostTitle.MEDIUM = 'medium';
PostTitle.LARGE = 'large';
PostTitle.SINGLE = 'single';
PostTitle.SEARCH = 'search';
PostTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  lineCount: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  to: PropTypes.string,
  textColorClassName: PropTypes.string.isRequired,
  applyFeedDesign: PropTypes.bool.isRequired,
  isInPostPage: PropTypes.bool.isRequired,
  linkClassName: PropTypes.string,
  layoutName: PropTypes.string,
  fullRoute: PropTypes.string,
  getPostClassName: PropTypes.func,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  postTitleFontSizeMobile: PropTypes.number,
};

PostTitle.defaultProps = {
  isInPostPage: false,
  style: {},
  showCategoryLabel: false,
};

const mapRuntimeToProps = (state) => ({
  postTitleFontSizeMobile: getPostTitleFontSizeMobile(state),
});

export default flowRight(
  withLayoutColorClasses,
  withIsFeedDesignEnabled,
  withDeviceType,
  connect(mapRuntimeToProps),
)(PostTitle);
