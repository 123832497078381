import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';
import CommentCountCompact from '../../../comments/components/comment-count-compact';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withPermissions from '../../hoc/with-permissions';
import { getSocialSharingProviders } from '../../selectors/app-settings-selectors';
import {
  getCommentCount,
  getViewCount,
} from '../../store/post-counters/post-counters-selectors';
import LikeButtonWithCount from '../like-button-with-count';
import PostIconRow from '../post-icon-row';
import PostSocialActions from '../post-social-actions';
import { connect } from '../runtime-context';
import { HorizontalSeparator } from '../separator';
import ViewCountCompact from '../view-count-compact';
import styles from './post-main-actions-mobile.scss';

const PostMainActionsMobile = ({
  canRender,
  post,
  incrementPostLikeCount,
  forPublicUser,
  isMetadataFooterVisible,
  showViewCount,
  showCommentCount,
  showLikeCount,
  showShareButtons,
  viewCount,
  totalComments,
  enabledProviders,
}) => (
  <div className={styles.container}>
    {showShareButtons &&
      canRender('share', 'post', post, () => (
        <PostIconRow className={styles.shareButtons} postId={post.id}>
          <PostSocialActions
            postSlug={post.slug}
            path={`/${post.slug}`}
            postId={resolveId(post)}
            enabledProviders={enabledProviders}
          />
        </PostIconRow>
      ))}
    <HorizontalSeparator />
    {isMetadataFooterVisible && (
      <div className={styles.flexContainer}>
        <div className={styles.stats}>
          {showViewCount && <ViewCountCompact count={viewCount} tabIndex={0} />}
          {showCommentCount && (
            <CommentCountCompact count={totalComments} tabIndex={0} />
          )}
        </div>
        {showLikeCount && (
          <LikeButtonWithCount
            onClick={forPublicUser(() =>
              incrementPostLikeCount(resolveId(post)),
            )}
            entity={post}
          />
        )}
      </div>
    )}
  </div>
);

PostMainActionsMobile.propTypes = {
  post: PropTypes.object.isRequired,
  incrementPostLikeCount: PropTypes.func.isRequired,
  canRender: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isMetadataFooterVisible: PropTypes.bool,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showShareButtons: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  enabledProviders: PropTypes.array.isRequired,
};

PostMainActionsMobile.defaultProps = {
  showShareButtons: true,
};

const mapRuntimeToProps = (state, { post }, actions) => ({
  incrementPostLikeCount: actions.incrementPostLikeCount,
  viewCount: getViewCount(state, resolveId(post)),
  totalComments: getCommentCount(state, resolveId(post)),
  enabledProviders: getSocialSharingProviders(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withAuth,
  withFeedMetadataSettings,
)(PostMainActionsMobile);
