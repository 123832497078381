import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { flowRight, isEmpty, isEqual } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import {
  getCategoryPath,
  getFeedTitle,
  NormalizedCategory,
} from '@wix/communities-blog-client-common';
import withAuth, { WithAuth } from '../../hoc/with-auth';
import {
  getCurrentMatchPathname,
  getRouteParams,
} from '../../router/router-selectors';
import { getLastUpdatedDate } from '../../selectors/app-settings-selectors';
import { getViewerAllPostsLabelTranslation } from '../../selectors/viewer-all-post-label-translation-selectors';
import { getHeaderLinks } from '../../services/get-header-links';
import {
  getViewMode,
  isSeo,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';
import { getCategoriesSlice } from '../../store/categories/categories-selectors';
import { getSearchInputWidth } from '../../store/search-input/search-input-selectors';
import { connect } from '../runtime-context';
import { calculateHeaderItems } from './calculate-header-items';
import { HeaderNavigationAccessibilityAlert } from './header-navigation-accessibility-alert';
import { HeaderNavigationLink } from './header-navigation-link';
import { HeaderNavigationMoreButton } from './header-navigation-more-button';
import styles from './header-navigation.scss';

const LINK_PADDING = 40;

type OwnProps = {};

type Props = OwnProps &
  WithTranslation &
  WithAuth &
  ConnectedProps<typeof connector>;

type State = {
  accessibilityAlert: string | null;
  visibleCategories: NormalizedCategory[];
  moreCategories: NormalizedCategory[];
  moreCategoriesMenuWidth?: number;
};

export class HeaderNavigation extends PureComponent<Props, State> {
  state: State = {
    visibleCategories: this.props.categories,
    accessibilityAlert: null,
    moreCategories: [],
  };

  componentDidMount() {
    this.updateVisibleCategories();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const propsKeys: (keyof Props)[] = [
      'isAuthenticated',
      'searchInputWidth',
      'viewMode',
      'settingsUpdated',
      'hostWidth',
    ];
    const stateKeys: (keyof State)[] = ['visibleCategories', 'moreCategories'];
    const propsChanged = propsKeys.some(
      (key) => !isEqual(prevProps[key], this.props[key]),
    );
    const stateChanged = stateKeys.some(
      (key) => !isEqual(prevState[key], this.state[key]),
    );

    if (propsChanged) {
      this.setState(
        { visibleCategories: this.props.categories, moreCategories: [] },
        this.updateVisibleCategories,
      );
    }

    if (stateChanged) {
      this.updateVisibleCategories();
    }
  }

  onNavigation = (title: string) => () => {
    this.showAccessibilityAlert(
      this.props.t('header.accessibility-alert', {
        title,
        count: this.props.categories.length,
      }),
    );
  };

  shouldRenderMoreButton = (categories: NormalizedCategory[]) => {
    if (typeof window === 'undefined') {
      return false;
    }

    if (categories.length > 0) {
      return true;
    }

    const topNavigationContainer = document.querySelector(
      '.blog-header__navigation',
    );

    if (!topNavigationContainer) {
      return false;
    }

    const maxWidth = topNavigationContainer.getBoundingClientRect().width;
    const renderedItemsWidth = Array.from(
      topNavigationContainer.children,
    ).reduce(
      (acc, link, index) =>
        acc +
        link.getBoundingClientRect().width +
        (index === 0 ? 0 : LINK_PADDING),
      0,
    );

    return maxWidth < renderedItemsWidth;
  };

  addNavigationToHeaderLinks = (categories: NormalizedCategory[]) => {
    const { feedTitle, categoryPath } = this.props;
    return getHeaderLinks({ categories, feedTitle, categoryPath }).map((i) => ({
      ...i,
      onNavigation: this.onNavigation(i.text),
    }));
  };

  showAccessibilityAlert = (alert: string) => {
    this.setState({ accessibilityAlert: alert });
  };

  updateVisibleCategories() {
    const { categories } = this.props;
    const nextState = calculateHeaderItems(
      categories,
      this.state.visibleCategories,
      LINK_PADDING,
    );
    !isEmpty(nextState) && this.setState(nextState);
  }

  render() {
    const { visibleCategories, moreCategories, moreCategoriesMenuWidth } =
      this.state;
    const links = this.addNavigationToHeaderLinks(visibleCategories);

    return (
      <nav
        className={classNames(
          styles.topNavigation,
          'blog-header__navigation',
          'blog-navigation-container-font',
        )}
        aria-label="blog"
      >
        {this.state.accessibilityAlert && (
          <HeaderNavigationAccessibilityAlert
            accessibilityAlert={this.state.accessibilityAlert}
          />
        )}
        <ul className={styles.container}>
          {links.map((link, index) => (
            <HeaderNavigationLink
              key={link.key}
              index={index}
              link={link}
              currentPathDecoded={this.props.currentPathDecoded}
              linkArray={links}
            />
          ))}
          {this.shouldRenderMoreButton(moreCategories) ? (
            <HeaderNavigationMoreButton
              categories={moreCategories}
              width={moreCategoriesMenuWidth}
              currentPath={this.props.currentPath}
              addNavigationToHeaderLinks={this.addNavigationToHeaderLinks}
              showAccessibilityAlert={this.showAccessibilityAlert}
            />
          ) : null}
        </ul>
      </nav>
    );
  }
}

const connector = connect((state, ownProps, actions, host) => {
  const { page } = getRouteParams(state) || {};
  const currentMatchPathname = getCurrentMatchPathname(state);
  const currentPath = page
    ? currentMatchPathname.replace(`/page/${page}`, '')
    : currentMatchPathname;

  return {
    categories: getCategoriesSlice(state, 30),
    currentPath,
    hostWidth: host.dimensions.width,
    currentPathDecoded: decodeURIComponent(currentPath),
    searchInputWidth: getSearchInputWidth(state),
    feedTitle:
      getViewerAllPostsLabelTranslation(state) ||
      getFeedTitle(state) ||
      state.tpaSettings.settings?.allPostsFeedLabel,
    viewMode: getViewMode(state),
    settingsUpdated: getLastUpdatedDate(state),
    categoryPath: getCategoryPath(state),
    isSSR: isSSR(state),
    isSEO: isSeo(state),
    paginationClearCollection: actions.paginationClearCollection,
  };
});

export default flowRight(
  connector,
  withAuth,
  withTranslation(),
)(HeaderNavigation);
